import axios from "axios";

export const namespaced = true;

export const state = () => ({});

export const getters = {};

export const actions = {
  // eslint-disable-next-line no-empty-pattern
  async newSession({}, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(`${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.new`, body, {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
          },
        })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e.response.data));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async interruptTask({}, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(
          `${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.interrupt`,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
            },
          }
        )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e));
    });
  },

  // eslint-disable-next-line no-empty-pattern
  async handleICE({}, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(`${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.ice`, body, {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
          },
        })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e));
    });
  },

  // eslint-disable-next-line no-empty-pattern
  async startSession({}, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(
          `${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.start`,
          body,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
            },
          }
        )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e));
    });
  },

  // eslint-disable-next-line no-empty-pattern
  async repeat({}, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(`${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.task`, body, {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
          },
        })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e));
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async stopSession({}, body) {
    return new Promise((resolve, rejected) => {
      axios
        .post(`${process.env.VUE_APP_HEYGEN_SERVER_URL}/streaming.stop`, body, {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": process.env.VUE_APP_HEYGEN_API_KEY,
          },
        })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((e) => rejected(e));
    });
  },
};
export const mutations = {};
