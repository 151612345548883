/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: () => import("@/pages/index"),
      name: "index",
      beforeEnter: (to, from, next) => {
        if (store.state.auth.loggedIn) next({ name: "dashboard" });
        else next();
      },
    },

    {
      path: "/dashboard",
      component: () => import("@/pages/dashboard"),
      name: "dashboard",
      meta: {
        requiresAuth: true,
      },
    },
    
    {
      path: "/*",
      component: () => import("@/layouts/error"),
      name: "error",
      meta: {
        layout: "error",
      },
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.auth.loggedIn) {
    next({ name: "index" });
  } else {
    next();
  }
});

export default router;
