<template>
  <div
    class="container d-flex justify-content-center align-items-center"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "default",
};
</script>