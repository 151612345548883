import { createStore } from "vuex";

const auth = require("./auth");
const expertmind = require("./expertmind");
const heygen = require("./heygen");
const errors = require("./errors");

export default createStore({
  modules: { auth, errors, expertmind, heygen },
});
